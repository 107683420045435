import axios from 'axios';
import qs from 'qs';
import { notification } from 'config';

const { REACT_APP_API_BASE_URL } = process.env;

const httpClient = axios.create({
  baseURL: REACT_APP_API_BASE_URL,
  timeout: 2 * 60 * 1000,
});

const paramsSerializer = params => {
  return qs.stringify(params, { indices: false });
};

const injectStringParams = (str, params = {}) => {
  return str.replace(/:(\w+)/g, (match, param) => params[param]);
};

httpClient.interceptors.request.use(config => {
  const rawData = localStorage.getItem('spot_my_admin');
  if (rawData) {
    config.headers.Authorization = `Bearer ${JSON.parse(rawData).jwt}`;
  }
  config.paramsSerializer = paramsSerializer;
  if (config.requestParams) {
    // URL templates replacer
    config.url = injectStringParams(config.url, config.requestParams);
  }
  return config;
});

httpClient.interceptors.response.use(
  response => response.data,
  err => {
    if (err?.response?.status === 401) {
      localStorage.removeItem('spot_my_admin');
      window.location.reload();
    }
    notification.error({
      message: err?.response?.data?.message || err?.response?.statusText || err?.message,
    });
    return Promise.reject(err);
  },
);

export default httpClient;
