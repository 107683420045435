import { useState, useMemo, useCallback } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import qs from 'qs';
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  InputNumber,
  Checkbox,
  Radio,
  Image,
  Tag,
  Typography,
} from 'antd';
import { ArrowLeftOutlined, CloseCircleOutlined } from '@ant-design/icons';

import api from 'services/API';
import { useQuery } from 'hooks';
import { commonUtilities, timeUtilities } from 'utils';
import { gameStatuses, notification, prizeConditions as prizeConditionsConfig } from 'config';

import Levels from 'pages/Games/GameEdit/Levels';
import { Button, PopConfirm } from 'components';
import fallback from 'assets/images/fallback.svg';

import { updatingGameForm } from './settings';
import {
  Wrapper,
  Header,
  ImageWrapper,
  DescriptionWrapper,
  StatusWrapper,
  ButtonsWrapper,
} from './styles';

const { isNotEmptyArray } = commonUtilities;
const { fromMSToHours, fromMSToMinutes, fromHoursToMS, fromMinutesToMS } = timeUtilities;

const { TextArea } = Input;
const { Option } = Select;

const {
  nameField,
  descriptionField,
  withPrizeField,
  prizeDescriptionField,
  prizeConditionsField,
  usersCountField,
  redeemingPrizeField,
  submitButton,
} = updatingGameForm;

const GameEdit = () => {
  const history = useHistory();
  const { queryParams } = useLocation();
  const [form] = Form.useForm();
  const { gameId } = useParams();
  const [game, setGame] = useState({});
  const [editablePrizeConditionsData, setEditablePrizeConditionsData] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const [changedForm, setChangedForm] = useState(false);

  const availableGameStatuses = game?.status ? gameStatuses[game.status].nextStatuses : [];

  const { refetch } = useQuery('game', async () => await api.getGame(gameId).then(data => data), {
    onSuccess: (game = {}) => updateGameForm(game),
  });

  const { data: fetchedPrizeConditions, isFetched } = useQuery(
    'prizeConditions',
    async () => await api.getPrizeConditions().then(data => data),
  );

  const updateGameForm = (game = {}) => {
    let timeInterval = null;
    if (isNotEmptyArray(game.prizeConditions) && game.prizeConditions[0].data?.timeInterval) {
      const prizeCondition = game?.prizeConditions[0];
      timeInterval =
        prizeCondition.id === prizeConditionsConfig.TOP_EARLIEST
          ? fromMSToMinutes(prizeCondition.data.timeInterval)
          : fromMSToHours(prizeCondition.data.timeInterval);
    }

    setGame(game);
    form.setFieldsValue({
      status: game?.status || '',
      name: game?.name || '',
      description: game?.description || '',
      withPrize: game?.withPrize || false,
      prizeDescription: game?.prizeDescription || '',
      redeemingPrize: game?.redeemingPrize || '',
      ...(isNotEmptyArray(game.prizeConditions) &&
        game.prizeConditions[0].data && {
          prizeConditions: game.prizeConditions[0].id || null,
          usersCount: game.prizeConditions[0].data?.usersCount || null,
          timeInterval,
        }),
    });
    setEditablePrizeConditionsData(
      isNotEmptyArray(game.prizeConditions) && game.prizeConditions[0].data
        ? {
            ...game.prizeConditions[0].data,
            ...(timeInterval && {
              timeInterval,
            }),
          }
        : {},
    );
    if (!isEmpty(game) && isEmpty(initialValues)) {
      setInitialValues(form.getFieldsValue());
    }
  };

  const formatPrizeConditions = useCallback(
    prizeConditions => {
      return prizeConditions.map(condition => {
        let defaultPrizeConditionsData = {};

        if (isNotEmptyArray(game.prizeConditions) && condition.id === game.prizeConditions[0].id) {
          defaultPrizeConditionsData = game.prizeConditions[0].data;
        } else {
          condition.data.forEach(({ key, value }) => (defaultPrizeConditionsData[key] = value));
        }

        const { prizeConditions } = form.getFieldsValue();
        const isCheckedPrizeCondition = prizeConditions === condition.id;

        const newData = isCheckedPrizeCondition
          ? editablePrizeConditionsData
          : defaultPrizeConditionsData;

        const formattedData = {};

        Object.keys(newData).map(key => {
          if (key === 'timeInterval' && !isCheckedPrizeCondition) {
            return (formattedData[key] =
              condition.id === prizeConditionsConfig.TOP_EARLIEST
                ? fromMSToMinutes(newData[key])
                : fromMSToHours(newData[key]));
          } else {
            return (formattedData[key] = newData[key]);
          }
        });

        const usersCountRegExp = /{usersCount}/gi;
        const timeIntervalRegExp = /{timeInterval}/gi;

        return {
          ...condition,
          data: formattedData,
          title: condition.title
            .replace(usersCountRegExp, formattedData?.usersCount)
            .replace(timeIntervalRegExp, formattedData?.timeInterval),
        };
      });
    },
    [editablePrizeConditionsData, form, game.prizeConditions],
  );

  const availablePrizeConditions = useMemo(
    () =>
      isNotEmptyArray(fetchedPrizeConditions)
        ? formatPrizeConditions(
            fetchedPrizeConditions.filter(({ id }) => (game?.sponsored ? id === 2 : id !== 2)),
          )
        : [],
    [fetchedPrizeConditions, game, formatPrizeConditions],
  );

  const onChangeStatus = async status => {
    await api.updateGameStatus(gameId, { status });
    setGame({ ...game, status });
    notification.success({
      message: `The game's status is ${status}`,
    });
  };

  const handlePrizeChange = async e => {
    setGame({ ...game, withPrize: e.target.checked });

    if (!e.target.checked) {
      form.setFieldsValue({
        prizeDescription: initialValues.prizeDescription,
        prizeConditions: initialValues.prizeConditions,
        usersCount: initialValues.usersCount,
        redeemingPrize: initialValues.redeemingPrize,
      });
    }
    handleValuesChange();
  };

  const handleChangePrizeCondition = e => {
    const checkedPrizeCondition = availablePrizeConditions.find(({ id }) => id === e.target.value);
    form.setFieldsValue(checkedPrizeCondition.data);
    setEditablePrizeConditionsData(checkedPrizeCondition.data);
  };

  const handleChangeUsersCount = usersCount => {
    if (usersCount) {
      setEditablePrizeConditionsData({ ...editablePrizeConditionsData, usersCount });
    }
  };

  const updateGame = async () => {
    const { withPrize, ...dataWithPrize } = form.getFieldsValue();
    const {
      prizeDescription,
      redeemingPrize,
      prizeConditions: prizeConditionsID,
      usersCount,
      timeInterval,
      ...dataWithoutPrize
    } = dataWithPrize;

    const formattedPrizeConditionsData = {};

    Object.keys(editablePrizeConditionsData).map(key => {
      if (key === 'timeInterval') {
        return (formattedPrizeConditionsData[key] =
          prizeConditionsID === prizeConditionsConfig.TOP_EARLIEST
            ? fromMinutesToMS(editablePrizeConditionsData[key])
            : fromHoursToMS(editablePrizeConditionsData[key]));
      } else {
        return (formattedPrizeConditionsData[key] = editablePrizeConditionsData[key]);
      }
    });

    await api.updateGame(game.id, {
      withPrize,
      ...(withPrize
        ? {
            ...dataWithoutPrize,
            prizeDescription,
            redeemingPrize,
            prizeConditions: [
              {
                id: prizeConditionsID,
                data: formattedPrizeConditionsData,
              },
            ],
          }
        : dataWithoutPrize),
    });
    notification.success({
      message: `The game was updated successfully`,
    });
    const newInitialValues = form.getFieldsValue();
    setChangedForm(false);
    setInitialValues(newInitialValues);
  };

  const deleteGame = async () => {
    await api.deleteGame(gameId);
    history.push('/games');
    notification.success({
      message: `The game's deleted`,
    });
  };

  const handleValuesChange = () => {
    const newValues = form.getFieldsValue();
    const isFormChanged = !isEqual(initialValues, newValues);
    setChangedForm(isFormChanged);
  };

  return (
    <Wrapper>
      <Header>
        <Link to={`/games?${qs.stringify(queryParams)}`}>
          <ArrowLeftOutlined /> Games
        </Link>
        {isFetched && (
          <StatusWrapper>
            <Typography.Title level={5} className="status">
              Status:{' '}
            </Typography.Title>
            {isNotEmptyArray(availableGameStatuses) ? (
              <Form.Item style={{ marginBottom: 0 }}>
                <Select
                  style={{ width: 170 }}
                  value={gameStatuses[game.status].title}
                  onChange={onChangeStatus}
                >
                  {availableGameStatuses.map(key => (
                    <Option key={key} value={key}>
                      {gameStatuses[key].title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            ) : game?.status ? (
              <Tag color={gameStatuses[game.status].color} style={{ fontSize: 14 }}>
                {gameStatuses[game.status].title.toUpperCase()}
              </Tag>
            ) : null}
          </StatusWrapper>
        )}
      </Header>
      <Form
        form={form}
        {...updatingGameForm.settings}
        onFinish={updateGame}
        onValuesChange={handleValuesChange}
      >
        <Row gutter={16}>
          <Col span={8}>
            <DescriptionWrapper>
              <ImageWrapper>
                <Image width={150} height={150} src={game?.profile_image || fallback} />
              </ImageWrapper>
              <Form.Item {...nameField}>
                <Input size="large" />
              </Form.Item>
              <Form.Item {...descriptionField} className="description">
                <TextArea />
              </Form.Item>
            </DescriptionWrapper>
          </Col>
          <Col span={8}>
            <Form.Item {...withPrizeField}>
              <Checkbox onChange={handlePrizeChange}>Add Prize to Game</Checkbox>
            </Form.Item>
            <Form.Item
              {...prizeDescriptionField}
              rules={game.withPrize ? prizeDescriptionField.rules : null}
            >
              <TextArea rows={3} disabled={!game.withPrize} />
            </Form.Item>
            {isNotEmptyArray(availablePrizeConditions) && (
              <Form.Item
                {...prizeConditionsField}
                rules={game.withPrize ? prizeConditionsField.rules : null}
              >
                <Radio.Group onChange={handleChangePrizeCondition} disabled={!game.withPrize}>
                  {availablePrizeConditions.map(({ id, title }) => (
                    <Radio key={id} value={id}>
                      {title}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
            )}
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  {...usersCountField}
                  rules={game.withPrize ? usersCountField.rules : null}
                >
                  <InputNumber
                    min={1}
                    disabled={!game.withPrize}
                    onChange={handleChangeUsersCount}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              {...redeemingPrizeField}
              rules={game.withPrize ? redeemingPrizeField.rules : null}
            >
              <TextArea rows={3} disabled={!game.withPrize} />
            </Form.Item>
          </Col>
          <Col span={7} offset={1}>
            <ImageWrapper className="brand">
              <Form.Item valuePropName="checked">
                <Checkbox checked={game?.sponsored} disabled>
                  Sponsored Game
                </Checkbox>
              </Form.Item>
              <Image width={150} height={150} src={game?.brand_logo || fallback} />
            </ImageWrapper>
          </Col>
        </Row>
        <ButtonsWrapper>
          <PopConfirm
            icon={<CloseCircleOutlined style={{ color: 'red' }} />}
            titleContent="You won't be able to recover this game!"
            okText="Delete"
            okButtonProps={{ type: 'danger' }}
            onConfirm={deleteGame}
          >
            <Button danger>Delete game</Button>
          </PopConfirm>
          <Button {...submitButton} disabled={!changedForm}>
            Save changes
          </Button>
        </ButtonsWrapper>
      </Form>
      <Levels game={game} setGame={setGame} refetch={refetch} />
    </Wrapper>
  );
};

export default GameEdit;
